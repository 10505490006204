<template lang="html">
  <article
    v-if="!loading && data"
    class="card card--full"
  >
    <div class="card__graph font-centered">
      <h3 class="card__title font-size-tiny text-upper w-bold">
        Sesiones por rango etario
      </h3>
      <figure class="card__graph graph_js_output">
        <canvas :id="name" />
      </figure>
    </div>
  </article>

  <CardDonutSkeleton v-else />
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-dupe-keys */
import { onMounted, watch, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
// Libs
import axios from 'axios';
import dataPersistence from '../services/dataPersistence';
import { sleep } from '../utils/index';
import createChartDonut from '../utils/charts-methods/DonutChart';
// Components
import CardDonutSkeleton from '@/components/Skeletons/CardDonutSkeleton.vue';

export default {
  components: {
    CardDonutSkeleton,
  },
  props: {
    name: String,
    metodo: String,
    title: String,
    source: String,
    api: String,
    metricsTag: String,
    dataSource: String,
    dimensionsTag: String

  },
  setup(props) {
    const store = useStore();
    // Props
    const { name, metodo, title, source, api, metricsTag, dimensionsTag, dataSource } = toRefs(props);

    // States
    const keyStorage = `${title.value.split(' ').join('-')}-${metodo.value}-${
      name.value
    }`;
    const URL = `${source.value}/${dataSource.value}/${metodo.value}/${name.value}/`;
    const data = ref(dataPersistence.getItem(keyStorage) || null);
    const loading = ref(false);
    // Computed
    const fechaReporte = computed(() => store.getters.getFechaReporte);
    const titleIfData = computed(() => {
      let emptyDataset = 0;
      if (data.value) {
        data.value.forEach((el) => {
          if (el.dataset == []) {
            emptyDataset++;
          }
        });

        if (data.value.length == emptyDataset) {
          return `${title.value} - Sin datos`;
        }
      }

      return title.value;
    });

    // Methods
    const fetchData = () => {
      axios
        .post(URL, {...fechaReporte.value, metricsTag: metricsTag.value, dimensionsTag: dimensionsTag.value})
        .then((response) => {
          data.value = response.data;
          dataPersistence.setItem(keyStorage, response.data);
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Mounted
    onMounted(() => {
      if (data.value) {
        createChartDonut(name.value, data.value);
      }

      if (!data.value) {
        loading.value = true;
        fetchData();
      }
    });

    // Watcher's
    watch(fechaReporte, () => {
      loading.value = true;
      fetchData();
    });

    watch(data, async () => {
      // TODO - Mejorar comportamiento del render del Charts
      await sleep(100).then(() => {
        createChartDonut(name.value, data.value);
      });
    });
    return { data, api, loading, titleIfData };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p,
  .dona {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
    height: 25px;
  }
  .dona {
    height: 200px;
    width: 200px;
    border-radius: 50% !important;
    margin: 10 auto;
    background: #e3e3e3 !important;
  }
}
</style>
