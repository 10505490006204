<template lang="html">
  <article
    v-if="!loading && data"
    class="card card--medium"
  >
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Rango {{ data['response_body'][card_id].nombre_dimension }}
      </h3>
      <p
        v-if="data['response_body'][card_id].dato.toString().indexOf('.') != -1"
        class="card__meta font-size-big"
      >
        {{ parseFloat(data['response_body']).toFixed(2) }}
      </p>
      <p
        v-else
        class="card__meta font-size-big"
      >
        {{ data['response_body'][card_id].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
      </p>
      <div class="card__data">
        <div
          v-for="compare in data['response_body'][card_id].comparacion"
          :key="compare"
          class="card__data__item"
        >
          <h4 class="card__title font-size-short">
            {{ data['response_body'][card_id].nombre_dimension }}
          </h4>
          <p
            v-if="compare.dato.toString().indexOf('.') != -1"
            class="card__meta font-size-tiny"
          >
            {{ parseFloat(compare.dato).toFixed(2) }}
          </p>
          <p
            v-else
            class="card__meta font-size-tiny"
          >
            {{ compare.dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="compare.incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(compare.incremento).toFixed(2) }}%
          </p>
          <p
            v-if="compare.incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(compare.incremento).toFixed(2) }}%
          </p>
        </div>
      </div>
    </div>
  </article>

  <article
    v-else
    class="card card--medium loading"
  >
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Sin título
      </h3>
      <p class="card__meta font-size-big">
        000
      </p>
      <div class="card__data">
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            Sin título
          </h4>
          <p class="card__meta font-size-tiny">
            000
          </p>
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            Sin título
          </h4>
          <p class="card__meta font-size-tiny">
            000
          </p>
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
  /* eslint-disable no-unused-vars */
  /* eslint-disable vue/no-dupe-keys */
  import { onMounted, watch, ref, toRefs, computed } from 'vue'
  import { useStore } from 'vuex'
  // Libs
  import axios from 'axios'
  import dataPersistence from '../services/dataPersistence'

  export default {
    props: {
      name: String,
      metodo: String,
      card_id: String,
      source: String,
      metricsTag: String,
      dataSource: String,
      dimensionsTag: String

    },
    setup(props) {
      const store = useStore()
      // Props
      const { name, metodo, card_id, source, metricsTag, dimensionsTag, dataSource } = toRefs(props)

      // States
      const keyStorage = `${card_id.value}-${metodo.value}-${name.value}`
      const URL = `${source.value}/${dataSource.value}/${metodo.value}/${name.value}/`
      const data = ref(dataPersistence.getItem(keyStorage) || null)
      const loading = ref(false)
      // Computed
      const fechaReporte = computed(() => store.getters.getFechaReporte)

      // Methods
      const fetchData = () => {
        axios
          .post(URL, {...fechaReporte.value, metricsTag: metricsTag.value, dimensionsTag: dimensionsTag.value})
          .then(response => {
            data.value = response.data
            dataPersistence.setItem(keyStorage, response.data)
            loading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }

      // Mounted
      onMounted(() => {
        if (!data.value) {
          loading.value = true
          fetchData()
        }
      })

      // Watcher's
      watch(fechaReporte, () => {
        loading.value = true
        fetchData()
      })

      return { data, loading, card_id, name }
    },
  }
</script>

<style lang="scss" scoped>
  .loading {
    h3,
    h4,
    p {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
    }
  }
  .gr-3.card--big {
    max-width: 25%;
  }
</style>
