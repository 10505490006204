<template>
  <main>
    <section class="horizon">
      <div class="container ">
        <div class="in-between">
          <h2 class="horizon__title">
            DATOS DEMOGRÁFICOS
          </h2>
        </div>

        <div class="row">
          <div class="gr-6 card--group">
            <div class="row">
              <cards-doughnut
                :source="mongo"
                data-source="dashboard"
                turno="0"
                metodo="analytics"
                name="userAgeBracket"
                title="SESIONES POR RANGO ETARIO"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />

              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="1"
                card_id="18-24"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="2"
                card_id="25-34"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="3"
                card_id="35-44"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="4"
                card_id="45-54"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="5"
                card_id="55-64"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
              <cards-view
                clase="gr-2"
                :source="mongo"
                data-source="dashboard"
                turno="6"
                card_id="65+"
                metodo="analytics"
                name="userAgeBracket"
                metrics-tag="sessions"
                dimensions-tag="userAgeBracket"
              />
            </div>
          </div>
          <div class="gr-6 card--group">
            <div class="row">
              <cards-cuadruple-view
                :source="mongo"
                data-source="dashboard"
                turno="7"
                metodo="analytics"
                name="userGender"
                metrics-tag="sessions"
                dimensions-tag="userGender"
              />
              <cards-cuadruple-devices
                :source="mongo"
                data-source="dashboard"
                turno="8"
                metodo="analytics"
                name="deviceCategory"
                metrics-tag="sessions"
                dimensions-tag="deviceCategory"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  import Cards from '@/components/CardsData.vue'
  import DoughNut from '@/components/DoughNut.vue'
  import CardsCuadruple from '@/components/CardsCuadrupleGender.vue'
  import CardsCuadrupleDevices from '@/components/CardsCuadrupleDevices.vue'

  export default {
    name: 'Dashboard',
    components: {
      'cards-view': Cards,
      'cards-doughnut': DoughNut,
      'cards-cuadruple-view': CardsCuadruple,
      'cards-cuadruple-devices': CardsCuadrupleDevices,
    },
    setup() {
      const store = useStore()
      const mongo = computed(() => store.getters.getMongoURL)
      const analytics = computed(() => store.getters.getAnalyticsURL)
      const pruebaAnalytics = computed(() => store.getters.getPruebaAnalyticsURL)
      const dashboardDB = computed(() => store.getters.getDBDashboard)
    return { mongo, analytics, pruebaAnalytics, dashboardDB }
    },
  }
</script>
