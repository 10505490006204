<template lang="html">
  <article
    v-if="!loading && data"
    class="card card--big card--horizontal card--cuadruple"
    style="flex-basis: 100%; width: 100%; max-width: 100%;"
  >
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Móvil
      </h3>
      <p class="card__meta font-size-big">
        {{ data['response_body']['mobile'].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
      </p>
      <div class="card__data">
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['mobile']['comparacion'][0].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['mobile']['comparacion'][0].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['mobile']['comparacion'][0].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['mobile']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['mobile']['comparacion'][0].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['mobile']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
        </div>
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['mobile']['comparacion'][1].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['mobile']['comparacion'][1].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['mobile']['comparacion'][1].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['mobile']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['mobile']['comparacion'][1].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['mobile']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
        </div>
      </div>
    </div>
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Desktop
      </h3>
      <p class="card__meta font-size-big">
        {{ data['response_body']['desktop'].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
      </p>
      <div class="card__data">
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['desktop']['comparacion'][0].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['desktop']['comparacion'][0].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['desktop']['comparacion'][0].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['desktop']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['desktop']['comparacion'][0].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['desktop']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
        </div>
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['desktop']['comparacion'][1].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['desktop']['comparacion'][1].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['desktop']['comparacion'][1].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['desktop']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['desktop']['comparacion'][1].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['desktop']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
        </div>
      </div>
    </div>
    <div class="card__body font-centered">
      <h3 class="card__title font-size-tiny text-upper w-extrabold">
        Tablet
      </h3>
      <p class="card__meta font-size-big">
        {{ data['response_body']['tablet'].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
      </p>
      <div class="card__data">
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['tablet']['comparacion'][0].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['tablet']['comparacion'][0].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['tablet']['comparacion'][0].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['tablet']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['tablet']['comparacion'][0].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['tablet']['comparacion'][0].incremento).toFixed(2) }}%
          </p>
        </div>
        <div class="card__data__item">
          <h4 class="card__title font-size-short">
            {{ data['response_body']['tablet']['comparacion'][1].titulo }}
          </h4>
          <p class="card__meta font-size-tiny">
            {{ data['response_body']['tablet']['comparacion'][1].dato.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
          </p>
          <p
            v-if="data['response_body']['tablet']['comparacion'][1].incremento >= 0"
            class="card__meta font-size-tiny cypher-up"
          >
            {{ parseFloat(data['response_body']['tablet']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
          <p
            v-if="data['response_body']['tablet']['comparacion'][1].incremento < 0"
            class="card__meta font-size-tiny cypher-down"
          >
            {{ parseFloat(data['response_body']['tablet']['comparacion'][1].incremento).toFixed(2) }}%
          </p>
        </div>
      </div>
    </div>
    <div
      class="card__graph font-centered"
      style="max-width:100%;height:370px; padding-top:35px"
    >
      <div class="card__graph font-centered">
        <h3 class="card__title font-size-tiny text-upper w-bold">
          Sesiones por Dispositivo
        </h3>
        <figure class="card__graph graph_js_output">
          <canvas :id="name" />
        </figure>
      </div>
    </div>
  </article>
  <div
    v-else
    class=""
    style="width: 100%;"
  >
    <article
      class="card card--big card--horizontal loading"
      style="flex-basis: 100%; width: 100%; max-width: 100%;"
    >
      <div class="card__body font-centered">
        <h3 class="card__title font-size-tiny text-upper w-extrabold">
          Sin título
        </h3>
        <p class="card__meta font-size-big">
          000
        </p>
        <div class="card__data">
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
        </div>
      </div>
      <div class="card__body font-centered">
        <h3 class="card__title font-size-tiny text-upper w-extrabold">
          Sin título
        </h3>
        <p class="card__meta font-size-big">
          000
        </p>
        <div class="card__data">
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
        </div>
      </div>
      <div class="card__body font-centered">
        <h3 class="card__title font-size-tiny text-upper w-extrabold">
          Sin título
        </h3>
        <p class="card__meta font-size-big">
          000
        </p>
        <div class="card__data">
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
          <div class="card__data__item">
            <h4 class="card__title font-size-short">
              Sin título
            </h4>
            <p class="card__meta font-size-tiny">
              000
            </p>
            <p class="card__meta font-size-tiny cypher-up">
              000
            </p>
          </div>
        </div>
      </div>
    </article>
    <article
      class="card card--big card--horizontal loading"
      style="width: 100%; max-width: 100%;"
    >
      <div
        class="card__graph font-centered"
        style="max-width:100%;height:310px; padding-top:35px"
      >
        <div class="card__graph font-centered">
          <h3 class="card__title font-size-tiny text-upper w-bold">
            Sesiones por Dispositivo
          </h3>
          <figure class="card__graph graph_js_output">
            <div class="dona" />
          </figure>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
  /* eslint-disable no-unused-vars */
  /* eslint-disable vue/no-dupe-keys */
  import { onMounted, watch, ref, toRefs, computed } from 'vue'
  import { useStore } from 'vuex'
  // Libs
  import axios from 'axios'
  import dataPersistence from '../services/dataPersistence'
  import { sleep } from '../utils/index'
  import createChartDonut from '../utils/charts-methods/DonutChartDevices'

  export default {
    props: {
      name: String,
      metodo: String,
      source: String,
      turno: String,
      metricsTag: String,
      dataSource: String,
      dimensionsTag: String
    },
    setup(props) {
      const store = useStore()
      // Props
      const { name, metodo, source, turno, metricsTag, dimensionsTag, dataSource } = toRefs(props)

      // States
      const keyStorage = `${turno.value}-${metodo.value}-${name.value}`
      const URL = `${source.value}/${dataSource.value}/${metodo.value}/${name.value}/`
      const data = ref(dataPersistence.getItem(keyStorage) || null)
      const loading = ref(false)
      // Computed
      const fechaReporte = computed(() => store.getters.getFechaReporte)

      // Methods
      const fetchData = () => {
        axios
          .post(URL, {...fechaReporte.value, metricsTag: metricsTag.value, dimensionsTag: dimensionsTag.value,})
          .then(response => {
            data.value = response.data
            dataPersistence.setItem(keyStorage, response.data)
            loading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }

      // Mounted
      onMounted(() => {
        if (data.value) {
          createChartDonut(name.value, data.value)
        }

        if (!data.value) {
          loading.value = true
          fetchData()
        }
      })

      // Watcher's
      watch(fechaReporte, () => {
        loading.value = true
        fetchData()
      })

      watch(data, async () => {
        // TODO - Mejorar comportamiento del render del Charts
        await sleep(300).then(() => {
          createChartDonut(name.value, data.value)
        })
      })
      return { data, name, loading }
    },
  }
</script>

<style lang="scss" scoped>
  .loading {
    h3,
    h4,
    p,
    .dona {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
      height: 25px;
    }
    .dona {
      height: 180px;
      width: 180px;
      border-radius: 50% !important;
      margin: 10 auto;
      background: #e3e3e3 !important;
    }
  }
</style>
