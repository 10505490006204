import Chart from 'chart.js'

export default function createdDonutChart(id, data) {
      new Chart(id, {
        type: 'doughnut',
        data: {
          labels: ['Femenino', 'Masculino'],
          datasets: [
            {
              label: '# of Votes',
              data: [
                parseInt(data['response_body']['female'].dato),
                parseInt(data['response_body']['male'].dato),
              ],
              backgroundColor: ['rgba(255, 0, 0, 0.5)', 'rgba(0, 0, 255, 0.5)'],
              borderColor: ['rgba(255, 0, 0, 1)', 'rgba(0, 0, 255, 1)'],
              borderWidth: 0,
            },
          ],
        },
        options: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          plugins: {
            labels: {
              render: 'percentage',
              precision: 1,
              fontSize: 12,
              fontColor: '#fff',
              fontStyle: 'bold',
              textShadow: true,
              shadowBlur: 6,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: 'rgba(0,0,0,0.75)',
            },
          },
        },
      });
    }