import Chart from 'chart.js'

export default function createdDonutChart(id, data) {
    let hundredPercent = parseInt( data['response_body']['18-24'].dato ) + parseInt( data['response_body']['25-34'].dato ) + parseInt( data['response_body']['35-44'].dato ) + parseInt( data['response_body']['45-54'].dato ) + parseInt( data['response_body']['55-64'].dato ) + parseInt( data['response_body']['65+'].dato );
    new Chart(id, {
      type: 'doughnut',
       data: {
        labels: [
           data['response_body']['18-24'].nombre_dimension + ': ' + parseInt( data['response_body']['18-24'].dato ) + ' (' + (parseInt( data['response_body']['18-24'].dato ) * 100 / hundredPercent).toFixed(2) + '%)',
           data['response_body']['25-34'].nombre_dimension + ': ' + parseInt( data['response_body']['25-34'].dato ) + ' (' + (parseInt( data['response_body']['25-34'].dato ) * 100 / hundredPercent).toFixed(2) + '%)',
           data['response_body']['35-44'].nombre_dimension + ': ' + parseInt( data['response_body']['35-44'].dato ) + ' (' + (parseInt( data['response_body']['35-44'].dato ) * 100 / hundredPercent).toFixed(2) + '%)',
           data['response_body']['45-54'].nombre_dimension + ': ' + parseInt( data['response_body']['45-54'].dato ) + ' (' + (parseInt( data['response_body']['45-54'].dato ) * 100 / hundredPercent).toFixed(2) + '%)',
           data['response_body']['55-64'].nombre_dimension + ': ' + parseInt( data['response_body']['55-64'].dato ) + ' (' + (parseInt( data['response_body']['55-64'].dato ) * 100 / hundredPercent).toFixed(2) + '%)',
           data['response_body']['65+'].nombre_dimension + ': ' + parseInt( data['response_body']['65+'].dato ) + ' (' + (parseInt( data['response_body']['65+'].dato ) * 100 / hundredPercent).toFixed(2) + '%)'
        ],
        datasets: [{
          label: '# of Votes',
           data: [parseInt( data['response_body']['18-24'].dato ), parseInt( data['response_body']['25-34'].dato ), parseInt( data['response_body']['35-44'].dato ), parseInt( data['response_body']['45-54'].dato ), parseInt( data['response_body']['55-64'].dato ), parseInt( data['response_body']['65+'].dato )],
          backgroundColor: [
            'rgba(255, 0, 0, 0.5)',
            'rgba(0, 0, 255, 0.5)',
            'rgba(255, 255, 0, 0.5)',
            'rgba(0, 255, 0, 0.5)',
            'rgba(255, 0, 255, 0.5)',
            'rgba(0, 255, 255, 0.5)'
          ],
          borderColor: 'rgba(255,255,255,1)',
          borderWidth: 0
        }]
      },
      options: {
        title:{
          display:false
        },
        legend: {
          position: 'right',
          fullWidth: false,
          labels: {
            boxWidth: 15
          }
        },
        tooltips: {
          enabled: false
        }
      }
    });

  }