<template>
  <article class="card card--full loading">
    <div class="card__graph font-centered">
      <h3 class="card__title font-size-tiny text-upper w-bold">
        Sesiones por rango etario
      </h3>
      <figure class="card__graph graph_js_output">
        <div class="dona" />
      </figure>
      <div class="card__data">
        <div class="card__data__item">
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
        <div class="card__data__item">
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
        <div class="card__data__item">
          <p class="card__meta font-size-tiny cypher-up">
            000
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p,
  .dona {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
    height: 25px;
  }
  .dona {
    height: 200px;
    width: 200px;
    border-radius: 50% !important;
    margin: 10 auto;
    background: #e3e3e3 !important;
  }
}
</style>
